/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rezervace"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"Rezervace - Chalupa Ořešák u Kouřimi"} ogTitle={"Rezervace - Chalupa Ořešák u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-16we3ek css-42e4bw --parallax pb--60 pt--80" name={"uvod-0"} layout={"l13"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38340/941d08854278456caaa05ee409831187_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Poptávka ubytování</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":30,"paddingBottom":12}} name={"u6jl0xtn48"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Pro rezervaci vašeho pobytu prosím vyplňte krátký formulář.<br>~ <br>Volné termíny můžete ověřit v kalendáři obsazenosti,<br>&nbsp;na serveru e-chalupy.cz, v odkazu níže.&nbsp;<br>"}>
              </Text>

              <Button className="btn-box" content={"Kalendář obsazenosti"} url={"https://www.e-chalupy.cz/kalendar-obsazenosti.php?objekt=16994"} href={"https://www.e-chalupy.cz/kalendar-obsazenosti.php?objekt=16994"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"paddingBottom":42}} name={"kontakt-0"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 ff--2 fs--18 w--300" style={{"maxWidth":900}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"6avs3vf06f8o"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"cdhj0q4z90du"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"gngwpcnkq09i"},{"id":"gy4ozetzvagh","type":"text","name":"Termín pobytu"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"adc9hlaj1024"},{"name":"Odeslat","type":"submit","id":"ik5nva37w265"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c623v7 --parallax pb--60 pt--60" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/d878541b2cb24290bf055e1cddf64144_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+420 608 859 625<br>chalupa.oresak@gmail.com</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">~ Návrat do časů našich babiček ~</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Nová Ves III 44 <br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}